.category-container {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.single-category {
  width: 100%;
  height: 150px;
  position: relative;
  color: white;
  display: flex;
  cursor: pointer;
}
.single-category:hover {
  transform: scale(1.02);
}

.category-name {
  width: 80px;
  background-color: #fdb43d;
  height: 100%;
  margin: "auto";
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.category-image {
  width: 40%;
  height: 100%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
