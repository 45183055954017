.banner-section {
  width: 100%;
  display: flex;
  margin-top: 60px;
  height: 45vh;
  position: relative;
  text-align: center;
}

.banner-section-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 150px;
  color: white;
  font-family: "Light";
  font-weight: 500;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  /* -webkit-text-stroke: 2px black; */
  background: linear-gradient(to right, #ffffff, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  opacity: 95%;
}
@media (max-width: 768px) {
  .banner-section-text {
    font-size: 60px;
  }
}

@media (max-width: 768px) {
  .banner-section {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}
