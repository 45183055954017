.sliders {
  width: 100%;
  height: 100vh;
  max-width: 100%;
  overflow: none;
  resize: none;
  image-rendering: optimizeQuality;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  object-fit: cover;
  background-color: rgba(155, 168, 136, 0);
  background-color: rgb(171, 192, 216);
}
.res-slide-container {
  display: none;
}
@media (max-width: 768px) {
  .res-slide-container {
    display: block;
  }
  .sliders {
    height: 320px;
    width: 100%;
  }

  .slide-container {
    display: none;
  }
}
