.button-51 {
  background-color: transparent;
  border: 1px solid #fcb033;
  box-sizing: border-box;
  color: #00132c;
  font-family: "Avenir Next LT W01 Bold", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 23px;
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-51:hover,
.button-51:active {
  outline: 0;
}

.button-51:hover {
  background-color: transparent;
  cursor: pointer;
}

.button-51:before {
  background-color: #ffc05b;
  content: "";
  height: calc(100% + 3px);
  position: absolute;
  right: -7px;
  top: -9px;
  transition: background-color 300ms ease-in;
  width: 100%;
  z-index: -1;
}
.main-image {
  object-fit: cover;
  /* height: 400px; */
}
.resp-about {
  display: none;
}
.button-51:hover:before {
  background-color: #fcb033;
}
.youtube-container {
  position: relative;
  padding-bottom: 51.25%; /* 16:9 aspect ratio (divide 9 by 16 = 0.5625) */
  height: 0;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
}

.youtube-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  .main-image {
    /* height: 200px; */
    width: 100%;
    object-fit: cover;
  }
  .youtube-container {
    position: relative;
    padding-bottom: 51.25%; /* 16:9 aspect ratio (divide 9 by 16 = 0.5625) */
    height: 0;
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .youtube-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .button-51 {
    padding: 16px 32px;
  }
}

@media (max-width: 768px) {
  .youtube-videos {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    margin: 0;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .resp-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .video1 {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }
  .resp-about {
    display: block;
  }
}
