@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

$timing: 265ms;
$iconColor: #fdcc78;
$accent: #fda101;
$bluefade: #f1a520;
$gradient: #0c0801;

@mixin transformScale($size: 1) {
  transform: scale($size);
  -ms-transform: scale($size);
  -webkit-transform: scale($size);
}
.social-container {
  width: 50px;
  margin: 30vh 2vh;
  text-align: center;
  position: fixed;
  z-index: 3;
}

.social-icons {
  padding: 0;
  list-style: none;
  margin: 1em;
  display: flex;
  flex-direction: column;

  li {
    display: inline-block;
    margin: 0.15em;
    font-size: 1.3em;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;

    background: linear-gradient(45deg, $iconColor, $accent);
  }

  a {
    display: flex;

    &:before {
      @include transformScale();
      content: " ";
      border-radius: 100%;
      display: block;
      background: linear-gradient(45deg, $iconColor, $accent);
      transition: all $timing ease-out;
    }

    &:hover:before {
      transform: scale(0);
      transition: all $timing ease-in;
    }

    &:hover i {
      @include transformScale(1.3);
      color: $iconColor;
      background: -webkit-linear-gradient(#000000, #000000);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: all $timing ease-in;
    }
  }
}

@media (max-width: 768px) {
  .social-container {
    display: none;
  }
}
