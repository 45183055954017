.product-card:hover .product-hover {
  position: absolute;
  transform: translate(-0%, -100%);
  transition: opacity 0.5s, transform 0.5s;
  width: 100%;
  border-radius: 5px;
  /* opacity: 0;
 transform: translate(-50%, -50%) scale(0.8);
 transition: opacity 0.5s, transform 0.5s; */
}
.product-card:hover .product-card-details {
  /* display: none; */
  /* position: absolute;
  width: 100%;
  border-radius: 5px; */
}
.product-card:hover {
  /* transform: scale(1.05); */
  transition: transform 0.3s ease;
  cursor: pointer;
}
.product-hover {
  position: absolute;
  width: 100%;
  height: 390px;
  background-color: rgb(218, 219, 221);
  opacity: 0.6;
}

/* .overlay {
  position: absolute;
  bottom: 0;
  background: #fcb033;
  width: 100%;
  opacity: 0;
  transition: 0.9s ease;
  font-size: 25px;
  padding: 20px;
}

.container:hover .overlay {
  opacity: 1.5;
} */
.image-gallery {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 2px;
}

.image-gallery .column {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.image-gallery .column2 {
  display: flex;
  flex-direction: row;
  gap: 2px;
}
.image-item img {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  object-fit: cover;
}

.image-item1 img {
  width: 600px;
  border-radius: 5px;
  height: 450px;
  object-fit: cover;
  border: 1px solid #fcb033;
}
.image-item3 img {
  width: 280px;
  border-radius: 5px;
  height: 450px;
  object-fit: cover;
  border: 1px solid #fcb033;
}

.image-item4 img {
  width: 210px;
  border-radius: 5px;
  height: 450px;
  object-fit: cover;
  border: 1px solid #fcb033;
}

.button-3 {
  appearance: none;
  background-color: #fcb033;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.product-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resp-pic {
  display: none;
}
.res-bann {
  display: none;
}
@media only screen and (min-width: 768px) {
  .image-gallery {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .image-item1 img,
  .image-item2 img {
    height: 200px;
  }
}
@media (max-width: 768px) {
  .container {
    display: none;
  }
}
@media (max-width: 768px) {
  .image-gallery .column2 {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .image-item3 img,
  .image-item4 img {
    height: 200px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .resp-pic {
    display: flex;
  }
}
@media (max-width: 768px) {
  .single-pic-product {
    height: auto;
    width: 100%;
  }
  .res-bann {
    display: block;
  }
  .bann {
    display: none;
  }
  .product-hover {
    position: absolute;
    width: 100%;
    height: 400px;
    background-color: rgb(218, 219, 221);
    opacity: 0.6;
  }
}
