.phone-number,
.email,
.address {
  width: 250px;
  height: 200px;
  background-color: #fcb033;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-top: 30px;
}
.phone-item,
.email-item,
.address-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.phone-header,
.email-header,
.address-header {
  font-family: "Light";
  font-weight: bolder;
  margin: 5px;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .contact-form {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
} */

.form-contact {
  /* width: 45%; */
  background: white;
  border-radius: 4px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cont-form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.map-form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.field-input {
  font-size: 16px;
  line-height: 28px;
  padding: 8px 16px;
  width: 50%;
  min-height: 44px;
  border: unset;
  border-radius: 4px;
  outline-color: rgba(243, 148, 70, 0.5);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

.contact-form-label {
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: "Light";
  font-weight: bolder;
}
.stay-contact {
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: "Light";
  font-weight: bolder;
}

/* .contact-page {
  background-color: #eceeed;
} */

@media (max-width: 768px) {
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 70px; */
    width: 100%;
    overflow: hidden;
  }
}

@media (max-width: 768px) {
  .phone-number,
  .email,
  .address {
    height: 120px;
    width: 70%;
  }
}
@media (max-width: 768px) {
  .contact-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .map-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .form-contact {
    width: 50%;
    /* overflow: hidden; */
    height: 500px;
  }
}

@media (max-width: 768px) {
  .map {
    height: 400px;
    overflow: hidden;
    width: 100%;
    margin: 10px;
  }
}
